/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cloud_logic_custom": [
        {
            "name": "apinflstats",
            "endpoint": "https://zs8qi2592b.execute-api.us-east-2.amazonaws.com/main",
            "region": "us-east-2"
        },
        {
            "name": "apiplayoffbrackets",
            "endpoint": "https://4nwlzhj2vf.execute-api.us-east-2.amazonaws.com/main",
            "region": "us-east-2"
        }
    ],
    "aws_dynamodb_all_tables_region": "us-east-2",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "NFLStats-main",
            "region": "us-east-2"
        },
        {
            "tableName": "playoffBrackets-main",
            "region": "us-east-2"
        }
    ]
};


export default awsmobile;
